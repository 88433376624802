import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ReactPlayer from 'react-player/lazy';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Text, Footer, Layout, VideoModal } from '../../shared/ui';
import {
  InnerContainer,
  OuterContainer,
} from '../../shared/ui/Container/Container';
import { MinusIcon, PlayIcon, PlusIcon } from '../../assets';
import { Slider } from '../../shared/ui/Slider';
import { useModal } from '../../shared/hooks';
import { hideCursor, showCursor } from '../../shared/ui/Cursor';

// gsap.registerPlugin(ScrollTrigger);

const BrandedData: React.FC = (props) => {
  // @ts-ignore
  const data = props.data.contentfulFeaturedWork;
  const [isShow, setIsShow] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPlaying, setIsPlaying] = useState(false);
  const [accordianHeight, setAccordianHeight] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const videoModalOpt = useModal();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const el = document.getElementById('accordianRef');
      if (el) {
        setAccordianHeight(el?.scrollHeight);
      }
    } else {
      setAccordianHeight(0);
    }
  }, []);

  // useEffect(() => {
  //   const ctx = gsap.context(() => {
  //     gsap.utils.toArray('.text-animated').forEach((el: HTMLElement) => {
  //       ScrollTrigger.batch(el, {
  //         onEnter: (batch: Element[]) =>
  //           gsap.from(batch, {
  //             opacity: 0,
  //             delay: 1,
  //             y: 50,
  //             duration: 1,
  //             ease: 'power2.in',
  //           }),
  //         once: true,
  //       });
  //     });
  //   });

  //   return () => ctx.revert();
  // }, []);

  const handlePlayPause = () => {
    setSelectedVideo(
      data?.detailVideos?.[0] ||
        data?.videoPromo?.url ||
        data?.featuredVideo?.url
    );
    videoModalOpt.onOpen();
  };
  const handlePlayPauseImpact = () => {
    setSelectedVideo(data?.detailVideos?.[1]);
    videoModalOpt.onOpen();
  };

  return (
    <Layout backTitle={data?.title}>
      <VideoModal {...videoModalOpt}>
        <div
          className=" w-[95vw] sm:w-[74.5vw] h-[60vh] sm:h-[calc(100vh-130px)]"
          onMouseEnter={hideCursor}
          onMouseLeave={showCursor}
        >
          <ReactPlayer
            className="react-player"
            url={selectedVideo}
            controls
            playing
            width="100%"
            height="100%"
            muted
            loop
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              objectFit: 'contain',
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </VideoModal>
      <>
        <OuterContainer className=" mb-8 sm:mb-16">
          <div className="relative h-[50vw] sm:h-[40vw] w-full">
            {data?.slug !== 'freeportlng' && !isPlaying && (
              <div
                onClick={handlePlayPause}
                className=" absolute top-0 left-0 z-30 w-full h-full flex items-center justify-center mix-blend-exclusion"
              >
                <div className="w-[clamp(48px,5vw,72px)] h-[clamp(48px,5vw,72px)] bg-white flex items-center justify-center rounded-full">
                  <PlayIcon />
                </div>
              </div>
            )}
            {data?.thumbImgs?.[0] ? (
              <GatsbyImage
                alt={data?.thumbImgs?.[0]?.gatsbyImage}
                objectFit="cover"
                image={data?.thumbImgs?.[0]?.gatsbyImage}
                className="object-cover h-full sm:h-[40vw] w-full mb-11"
              />
            ) : (
              <div className=" w-full h-full">
                {data?.detailVideos?.[0] && (
                  <ReactPlayer
                    className="react-player"
                    url={
                      data.detailVideos?.[0] ||
                      data?.videoPromo?.url ||
                      data?.featuredVideo?.url
                    }
                    controls={false}
                    playing={false}
                    width="100%"
                    height="100%"
                    muted
                    light
                    playIcon={<div />}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </OuterContainer>

        <InnerContainer className="max-sm:mb-0">
          <Text
            type="head"
            className="text-animated mb-8 sm:mb-11 md:mb-20"
            title={data.title}
          />
          <div className="flex flex-col-reverse mb-0 md:flex-row sm:mb-10">
            <div className="w-[100%]">
              {/* <Text type="h6" title={data.subtitle} /> */}

              {!!data.brief && (
                <div>
                  <div className="text-animated text-base font-semibold uppercase mb-4">
                    Brief
                  </div>
                  <div className="text-animated text-base font-light mb-12 leading-6">
                    {renderRichText(data.brief)}
                  </div>
                </div>
              )}

              {!!data.approach && (
                <div>
                  <div className="text-animated text-base font-semibold uppercase mb-4">
                    Approach
                  </div>
                  <div className="text-animated text-base font-light mb-12 leading-6">
                    {renderRichText(data.approach)}
                  </div>
                </div>
              )}

              {!!data.impact && (
                <div>
                  <div className="text-animated text-base font-semibold uppercase mb-4">
                    Impact
                  </div>
                  <div className="text-animated text-base font-light mb-12 leading-6">
                    {renderRichText(data.impact)}
                  </div>
                </div>
              )}
            </div>

            <div className="w-[100%] md:max-w-[326px] md:ml-[12%] h-full mb-10">
              {data.client && (
                <div className="mb-8">
                  <p className="text-animated text-base font-semibold uppercase mb-2 xl:mb-3">
                    Client
                  </p>
                  <p className="text-animated text-base font-light">
                    {data.client}
                  </p>
                </div>
              )}

              {data.brand && (
                <div className="mb-8">
                  <p className="text-animated text-base font-semibold uppercase mb-2 xl:mb-3">
                    Brand
                  </p>
                  <p className="text-animated text-base font-light">
                    {data.brand}
                  </p>
                </div>
              )}

              {data.agency && (
                <div className="mb-8">
                  <p className="text-animated text-base font-semibold uppercase mb-2 xl:mb-3">
                    Agency
                  </p>
                  <p className="text-animated text-base font-light">
                    {data.agency}
                  </p>
                </div>
              )}

              {data.productionCompany && (
                <div className="mb-8">
                  <p className="text-animated text-base font-semibold uppercase mb-2 xl:mb-3">
                    Production Company
                  </p>
                  <p className="text-animated text-base font-light">
                    {data.productionCompany}
                  </p>
                </div>
              )}

              {data.director && (
                <div className="mb-8">
                  <p className="text-animated text-base font-semibold uppercase mb-2 xl:mb-3">
                    Director
                  </p>
                  <p className="text-animated text-base font-light">
                    {data.director}
                  </p>
                </div>
              )}

              {data.creativeDirector && (
                <div className="mb-8">
                  <p className="text-animated text-base font-semibold uppercase mb-2 xl:mb-3">
                    Creative Director
                  </p>
                  <p className="text-animated text-base font-light">
                    {data.creativeDirector}
                  </p>
                </div>
              )}

              <div>
                <p className="text-animated text-base font-semibold uppercase mb-2 xl:mb-3">
                  Services
                </p>
                <p className="text-animated text-base font-light">
                  {data.services}
                </p>
              </div>
            </div>
          </div>
        </InnerContainer>

        {data?.detailVideos?.[1] && (
          <OuterContainer className=" mb-12 sm:mb-32">
            <div className="relative h-[50vw] sm:h-[40vw] w-full">
              {!isPlaying && (
                <div
                  onClick={handlePlayPauseImpact}
                  className=" absolute top-0 left-0 z-30 w-full h-full flex items-center justify-center mix-blend-exclusion"
                >
                  <div className="w-[clamp(48px,5vw,72px)] h-[clamp(48px,5vw,72px)] bg-white flex items-center justify-center rounded-full">
                    <PlayIcon />
                  </div>
                </div>
              )}
              {data?.thumbImgs?.[1] && (
                <GatsbyImage
                  alt={data?.thumbImgs?.[1]?.gatsbyImage}
                  objectFit="cover"
                  image={data?.thumbImgs?.[1]?.gatsbyImage}
                  className="object-cover h-full sm:h-[40vw] w-full mb-11"
                />
              )}
              {/* {data?.detailVideos?.[0] && (
                <ReactPlayer
                  className="react-player"
                  url={data.detailVideos?.[1]}
                  controls={false}
                  playing={false}
                  width="100%"
                  height="100%"
                  muted
                  light
                  playIcon={<div />}
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                />
              )} */}
            </div>
          </OuterContainer>
        )}

        <div className="mb-12 sm:mb-[74px]">
          <Slider items={data.photos} />
        </div>

        {/* {data.imageGrid && (
          <GatsbyImage
            alt={data.imageGrid.gatsbyImage}
            objectFit="cover"
            image={data.imageGrid.gatsbyImage}
            className="mb-5 sm:mb-10"
          />
        )} */}

        {/* {data.photos?.map((photo) => (
          <GatsbyImage
            key={photo.contentful_id}
            alt={photo.title}
            image={photo.gatsbyImage}
            className="mb-5 sm:mb-10 h-img-xl"
          />
        ))} */}

        {(data?.creditAgency ||
          data?.creditProduction ||
          data?.creditEditorial ||
          data?.creditClient) && (
          <InnerContainer className=" mb-20 sm:mb-32">
            <h3
              className=" uppercase font-jakarta text-2xl font-semibold py-4 border-b border-[#ffffff1a] mb-12 sm:mb-4 flex items-center justify-between text-white"
              onClick={() => setIsShow(!isShow)}
            >
              Full Credits
              <span
                className={` mb-[-8px] sm:mb-[-16px] rotate-0 transition-all duration-500 ${
                  isShow && ' rotate-180'
                }`}
              >
                {isShow ? <MinusIcon /> : <PlusIcon />}
              </span>
            </h3>
            <div
              className="overflow-hidden transition-[max-height] duration-500"
              style={{
                maxHeight: isShow ? `${accordianHeight}px` : '0px',
              }}
            >
              <div
                className="flex gap-6 justify-between items-start flex-col sm:flex-row"
                id="accordianRef"
              >
                {data?.creditAgency && (
                  <div className=" w-full sm:w-2/4">
                    <h6 className=" text-white uppercase text-sm font-semibold mb-4">
                      Agency
                    </h6>
                    <div className="text-white mb-8 sm:mb-12 text-base font-light leading-6">
                      {renderRichText(data?.creditAgency)}
                    </div>
                  </div>
                )}
                {data?.creditClient && (
                  <div className=" w-full sm:w-2/4">
                    <h6 className=" text-white uppercase text-sm font-semibold mb-4">
                      Client
                    </h6>
                    <div className="text-white mb-8 sm:mb-12 text-base font-light leading-6">
                      {renderRichText(data?.creditClient)}
                    </div>
                  </div>
                )}
                <div className=" w-full sm:w-2/4">
                  {data?.creditProduction && (
                    <>
                      <h6 className=" text-white uppercase text-sm font-semibold mb-4">
                        PRODUCTION
                      </h6>
                      <div className="text-white mb-8 sm:mb-12 text-base font-light leading-6">
                        {renderRichText(data?.creditProduction)}
                      </div>
                    </>
                  )}

                  {data?.creditEditorial && (
                    <>
                      <h6 className=" text-white uppercase text-sm font-semibold mb-4">
                        Editorial
                      </h6>
                      <div className="text-white mb-8 sm:mb-12 text-base font-light leading-6">
                        {renderRichText(data?.creditEditorial)}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </InnerContainer>
        )}

        <Footer />
      </>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulFeaturedWork(id: { eq: $id }) {
      id
      title
      slug
      subtitle
      client
      agency
      brand
      productionCompany
      director
      creativeDirector
      services
      service
      brief {
        raw
      }
      impact {
        raw
      }
      approach {
        raw
      }
      creditAgency {
        raw
      }
      creditProduction {
        raw
      }
      creditEditorial {
        raw
      }
      creditClient {
        raw
      }
      videoUrl
      videoPromo {
        url
      }
      featuredVideo {
        url
      }
      description {
        raw
      }
      imageGrid {
        gatsbyImage(height: 1500, width: 3150)
      }
      photos {
        contentful_id
        title
        gatsbyImage(height: 900, width: 1600)
      }
      thumbImgs {
        id
        contentful_id
        gatsbyImage(height: 1440, width: 2560)
      }
      detailVideos
    }
  }
`;

export default BrandedData;
